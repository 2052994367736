import { Add } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Fab,
  Typography,
} from '@mui/material';
import { RoutableProps, route } from 'preact-router';
import { useState } from 'preact/hooks';
import { useAsync } from 'react-async-hook';

import { ErrorPage } from './error-page';
import { daoSignal } from './utils/signals';
import { getValueOrThrow } from './utils/typing';

export type HomeProps = RoutableProps;

export function Home(unused_props: HomeProps) {
  const dao = getValueOrThrow(daoSignal.value);

  const [lists, setLists] = useState<ReadonlyArray<string>>([]);

  const { loading, error } = useAsync(async () => {
    setLists(await dao.getAllLists());
  }, []);

  if (loading) {
    return <></>;
  }

  if (error) {
    return (
      <ErrorPage type="500 Server Error">
        <Typography>Failed to load the database.</Typography>
      </ErrorPage>
    );
  }

  return (
    <Box sx={{ padding: 2, width: '100%' }}>
      {lists.length == 0 && (
        <Typography variant="h4">
          Tap the <Add color="primary" /> button below to start.
        </Typography>
      )}
      {lists.map((list) => (
        <Card sx={{ marginBottom: 2, textAlign: 'center' }}>
          <CardActionArea sx={{ padding: 2 }}>
            <CardContent onClick={() => route(`/play/${list}`)}>
              <Typography variant="h4">{list}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}

      <Fab
        sx={{ position: 'fixed', bottom: 16, insetInlineEnd: 16 }}
        color="primary"
        aria-label="add"
        href="/create"
      >
        <Add />
      </Fab>
    </Box>
  );
}
