import { Box, Typography } from '@mui/material';
import { ComponentChildren } from 'preact';

export type ErrorPageProps = {
  type: string;
  default?: boolean;
  children?: ComponentChildren;
};

export function ErrorPage({ type, children }: ErrorPageProps) {
  return (
    <Box sx={{ flexDirection: 'column', padding: 2 }}>
      <Typography variant="h2">{type}</Typography>
      {children}
    </Box>
  );
}
