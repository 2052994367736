import { MoreVert } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useRouter } from 'preact-router';

import { ShortlistProps } from './shortlist';
import { menuAnchorSignal } from './utils/signals';

export function MenuIcon() {
  const [router] = useRouter<ShortlistProps>();
  const list = router?.matches?.list;

  return (
    <IconButton
      size="large"
      edge="end"
      color="inherit"
      disabled={!list}
      onClick={({ currentTarget }: Event) =>
        (menuAnchorSignal.value =
          currentTarget instanceof HTMLElement ? currentTarget : null)
      }
    >
      <MoreVert />
    </IconButton>
  );
}
