import {
  SwipeableListItem,
  SwipeableListItemProps,
} from 'react-swipeable-list';

import './shortlist-swipeable-list-item.css';

export type ShortlistSwipeableListItemProps = SwipeableListItemProps & {
  fullyFadedProgress: number;
  onOpacityChange: (opacity: number) => void;
};
export function ShortlistSwipeableListItem(
  props: ShortlistSwipeableListItemProps
) {
  function setOpacity(progress: number) {
    props.onOpacityChange(
      (props.fullyFadedProgress -
        Math.min(progress, props.fullyFadedProgress)) /
        props.fullyFadedProgress
    );
  }

  return (
    <SwipeableListItem
      {...props}
      onSwipeProgress={(progress, dragDirection) => {
        setOpacity(progress);
        props.onSwipeProgress?.(progress, dragDirection);
      }}
      onSwipeEnd={(dragDirection) => {
        setOpacity(0);
        props.onSwipeEnd?.(dragDirection);
      }}
    />
  );
}
