import { WarningAmber } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'preact/hooks';

export function PersistIcon() {
  const [isPersisted, setIsPersisted] = useState(true);
  useEffect(() => {
    void (async () => {
      setIsPersisted(await navigator.storage.persisted());
    })();
  }, [isPersisted]);

  return !isPersisted ? (
    <Tooltip title="This app requires permission to store your shortlists on your device">
      <IconButton
        size="large"
        edge="end"
        color="warning"
        onClick={async () => {
          setIsPersisted(await navigator.storage.persist());
        }}
      >
        <WarningAmber />
      </IconButton>
    </Tooltip>
  ) : (
    <></>
  );
}
