import { Delete, RestartAlt, Undo } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Fab,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Modal,
  Typography,
  Zoom,
} from '@mui/material';
import { RoutableProps, route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import { useAsync } from 'react-async-hook';
import {
  LeadingActions,
  SwipeableList,
  SwipeAction,
  TrailingActions,
} from 'react-swipeable-list';

import { ShortlistSwipeableListItem } from './shortlist-swipeable-list-item';
import { shuffle } from './utils/randomize';
import { daoSignal, menuAnchorSignal, titleSignal } from './utils/signals';
import { getValueOrThrow } from './utils/typing';

import 'react-swipeable-list/dist/styles.css';
import './shortlist.css';

type UndoStep = {
  loser: string;
  items: ReadonlyArray<string>;
};

type ModalData = {
  verb: string;
  action: () => Promise<void>;
};

export type ShortlistProps = { list?: string };

export function Shortlist({ list: listProp }: RoutableProps & ShortlistProps) {
  const dao = getValueOrThrow(daoSignal.value);
  const list = getValueOrThrow(listProp);

  const [items, setItems] = useState<ReadonlyArray<string>>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [shortlistLength, setShortlistLength] = useState(0);
  const [undoList, setUndoList] = useState<ReadonlyArray<UndoStep>>([]);

  const [touchIndex, setTouchIndex] = useState(-1);
  const [opacity, setOpacity] = useState(1);
  const [swapping, setSwapping] = useState(false);

  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(
      1 - (items.length - shortlistLength) / (totalItems - shortlistLength)
    );

    const maybeCheckmark =
      items.length && items.length <= shortlistLength ? '✅ ' : '';
    titleSignal.value = `${maybeCheckmark}Shortlist: ${list}`;
  }, [totalItems, shortlistLength, items]);

  const [modalData, setModalData] = useState<ModalData>();

  const { loading, error } = useAsync(async () => {
    const listRow = await dao.getList(list);
    if (!listRow) {
      route('/', true);
      return;
    }

    const itemRows = Array.from(await dao.getItemsFromList(list)).sort(shuffle);

    setTotalItems(itemRows.length);
    setShortlistLength(listRow.shortlistLength);
    setItems(
      itemRows.filter(({ rejected }) => !rejected).map(({ name }) => name)
    );
  }, []);

  async function reject(loser: string) {
    setSwapping(true);

    const survivors = items
      .slice(0, shortlistLength)
      .filter((item) => item !== loser);
    await dao.rejectItem(list, loser, survivors);

    setItems(items.filter((item) => item !== loser).sort(shuffle));
    setUndoList([{ loser, items }, ...undoList]);

    setSwapping(false);
  }

  async function undo() {
    const { loser, items } = undoList[0];
    const survivors = items
      .slice(0, shortlistLength)
      .filter((item) => item !== loser);

    await dao.undoRejectItem(list, loser, survivors);

    setItems([...items, loser]);
    setUndoList(undoList.slice(1));
  }

  async function resetList() {
    const itemRows = Array.from(await dao.resetList(list)).sort(shuffle);

    setItems(itemRows.map(({ name }) => name));
    setUndoList([]);
  }

  async function deleteList() {
    await dao.deleteList(list);

    route(`/`, true);
  }

  function leadingActions(item: string) {
    return (
      <LeadingActions>
        <SwipeAction onClick={() => void reject(item)} children="" />
      </LeadingActions>
    );
  }
  function trailingActions(item: string) {
    return (
      <TrailingActions>
        <SwipeAction onClick={() => void reject(item)} children="" />
      </TrailingActions>
    );
  }

  if (loading) {
    return <></>;
  }

  if (error) {
    route('/');
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <LinearProgress
        variant="determinate"
        color={items.length > shortlistLength ? 'secondary' : 'primary'}
        value={progress * 100}
      />

      <SwipeableList key={items} style={{ opacity: swapping ? 0 : opacity }}>
        {items.slice(0, shortlistLength).map((item, index) => (
          <ShortlistSwipeableListItem
            blockSwipe={items.length <= shortlistLength}
            className="candidate-list-item"
            fullyFadedProgress={75}
            leadingActions={leadingActions(item)}
            trailingActions={trailingActions(item)}
            onSwipeProgress={() => setTouchIndex(index)}
            onSwipeEnd={() => setTouchIndex(-1)}
            onOpacityChange={setOpacity}
          >
            <Card
              sx={{
                alignItems: 'stretch',
                display: 'flex',
                flexGrow: 1,
                marginX: 2,
                marginY: 1,
              }}
              style={
                touchIndex !== index
                  ? {
                      filter: `blur(${((1 - opacity) * 5).toFixed(2)}px)`,
                    }
                  : {}
              }
              elevation={items.length > shortlistLength ? 1 : 0}
            >
              <CardActionArea disabled={!items.length}>
                <CardContent>
                  <Typography variant="h4" textAlign="center">
                    {item}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </ShortlistSwipeableListItem>
        ))}
      </SwipeableList>

      <Zoom in={undoList.length > 0}>
        <Fab
          sx={{
            position: 'fixed',
            bottom: 16,
            insetInlineStart: 16,
          }}
          color="secondary"
          aria-label="undo"
          disabled={!undoList.length}
          onClick={() => undo()}
        >
          <Undo />
        </Fab>
      </Zoom>

      <Menu
        open={Boolean(menuAnchorSignal.value)}
        anchorEl={menuAnchorSignal.value}
        onClose={() => (menuAnchorSignal.value = null)}
      >
        <MenuItem
          onClick={() => {
            menuAnchorSignal.value = null;
            setModalData({ verb: 'reset', action: resetList });
          }}
        >
          <ListItemIcon>
            <RestartAlt />
          </ListItemIcon>
          Reset this list
        </MenuItem>
        <MenuItem
          onClick={() => {
            menuAnchorSignal.value = null;
            setModalData({ verb: 'delete', action: deleteList });
          }}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          Delete this list
        </MenuItem>
      </Menu>

      <Modal open={Boolean(modalData)} onClose={() => setModalData(undefined)}>
        <Card>
          <CardContent>
            <Typography>
              Are you sure you want to {modalData?.verb} this list?
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              onClick={() => {
                void modalData?.action();
                setModalData(undefined);
              }}
              color="warning"
            >
              Yes, {modalData?.verb}
            </Button>
            <Button onClick={() => setModalData(undefined)}>No</Button>
          </CardActions>
        </Card>
      </Modal>
    </Box>
  );
}
