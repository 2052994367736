import { Close } from '@mui/icons-material';
import { Button, IconButton, Snackbar, SnackbarContent } from '@mui/material';
import { StateUpdater } from 'preact/hooks';

export type NeedRefreshSnackbarProps = {
  needRefresh: boolean;
  setNeedRefresh: StateUpdater<boolean>;
  updateServiceWorker: (_: boolean) => Promise<void>;
};

export function NeedRefreshSnackbar({
  needRefresh,
  setNeedRefresh,
  updateServiceWorker,
}: NeedRefreshSnackbarProps) {
  return (
    <Snackbar open={needRefresh}>
      <SnackbarContent
        message={'Update available for Shortlists'}
        action={
          <>
            <Button
              variant="text"
              onClick={() => void updateServiceWorker(true)}
            >
              Reload
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setNeedRefresh(false)}
            >
              <Close fontSize="small" />
            </IconButton>
          </>
        }
      />
    </Snackbar>
  );
}
