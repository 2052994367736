import { DBSchema, IDBPDatabase, openDB } from 'idb';

import { dbSignal } from './signals';

export interface Item {
  list: string;
  name: string;
  rejected: boolean;
  survived: number;
}

export interface List {
  list: string;
  shortlistLength: number;
}

export interface ShortlistsDbSchema extends DBSchema {
  items: {
    key: [string, string];
    value: Item;
    indexes: {
      'by-list': string;
    };
  };
  lists: {
    key: string;
    value: List;
  };
}

export async function connect() {
  if (dbSignal.value) {
    return;
  }

  dbSignal.value = await openDB<ShortlistsDbSchema>('shortlists', 1, {
    upgrade(database /* , oldVersion, newVersion, transaction */) {
      if (!database.objectStoreNames.contains('lists')) {
        database.createObjectStore('lists', {
          keyPath: 'list',
        });
      }
      if (!database.objectStoreNames.contains('items')) {
        const itemsStore = database.createObjectStore('items', {
          keyPath: ['list', 'name'],
        });
        itemsStore.createIndex('by-list', 'list');
      }
    },
  });

  return dbSignal.value;
}

export type ShortlistsDb = IDBPDatabase<ShortlistsDbSchema>;
