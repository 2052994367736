import { computed, signal } from '@preact/signals';

import { ShortlistsDao } from './dao';
import { ShortlistsDb } from './db';

export const dbSignal = signal<ShortlistsDb | null>(null);
export const daoSignal = computed<ShortlistsDao | null>(
  () => dbSignal.value && new ShortlistsDao(dbSignal.value)
);

export const titleSignal = signal('Shortlists');
export const menuAnchorSignal = signal<HTMLElement | null>(null);
