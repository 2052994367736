import { ArrowBack } from '@mui/icons-material';
import {
  useMediaQuery,
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  createTheme,
  ThemeProvider,
  CssBaseline,
} from '@mui/material';
import { deepOrange, deepPurple } from '@mui/material/colors';
import { render } from 'preact';
import { route, Router, useRouter } from 'preact-router';
import { useEffect, useMemo } from 'preact/hooks';
import { useAsync } from 'react-async-hook';
import { useRegisterSW } from 'virtual:pwa-register/preact';

import { Create } from './create';
import { ErrorPage } from './error-page';
import { Home } from './home';
import { MenuIcon } from './menu-icon';
import { NeedRefreshSnackbar } from './need-refresh-snackbar';
import { PersistIcon } from './persist-icon';
import { Shortlist } from './shortlist';
import { connect } from './utils/db';
import { titleSignal } from './utils/signals';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';

function Index() {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({});

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: deepPurple,
          secondary: deepOrange,
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode]
  );

  const [router] = useRouter();

  useEffect(() => {
    document.title = titleSignal.value;
  });

  const { loading, error, result: db } = useAsync(connect, []);

  if (loading) {
    return <></>;
  }

  if (error || !db) {
    return (
      <ErrorPage type="500 Server Error">
        <Typography>Failed to load the database.</Typography>
      </ErrorPage>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            onClick={() => route('/')}
            disabled={router.path === '/'}
          >
            <ArrowBack />
          </IconButton>

          <Typography variant="h5" sx={{ flexGrow: 1 }}>
            {titleSignal.value}
          </Typography>

          <PersistIcon />
          <MenuIcon />
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <Router onChange={() => (titleSignal.value = 'Shortlists')}>
          <Home path="/" />
          <Create path="/create" />
          <Shortlist path="/play/:list?" />
          <ErrorPage type="404 Not Found" default />
        </Router>

        <NeedRefreshSnackbar
          needRefresh={needRefresh}
          setNeedRefresh={setNeedRefresh}
          updateServiceWorker={updateServiceWorker}
        />
      </Box>
    </ThemeProvider>
  );
}

render(<Index />, document.body);
