import { Add } from '@mui/icons-material';
import { Fab, FormLabel, Slider, Stack, TextField } from '@mui/material';
import { RoutableProps, route } from 'preact-router';
import { useEffect, useRef, useState } from 'preact/hooks';

import { daoSignal } from './utils/signals';
import { getValueOrThrow } from './utils/typing';

export type CreateProps = RoutableProps;

function textareaToArray(value: string) {
  if (!value.trim()) {
    return [];
  }

  return [
    ...new Set(
      value
        .trim()
        .split(/\s*\n+\s*/)
        .map((name) => name.trim())
    ),
  ];
}

export function Create(unused_props: CreateProps) {
  const dao = getValueOrThrow(daoSignal.value);

  const formRef = useRef<HTMLFormElement>();

  const [rawList, setRawList] = useState('');
  const [shortlistLength, setShortlistLength] = useState(5);
  const [rawNames, setRawNames] = useState('');

  const [list, setList] = useState('');
  useEffect(() => setList(rawList.trim()), [rawList]);

  const [names, setNames] = useState<string[]>([]);
  useEffect(() => setNames(textareaToArray(rawNames)), [rawNames]);

  const [listError, setListError] = useState('');
  useEffect(() => {
    void (async () => {
      const listExists = await dao.getList(list);

      setListError(listExists ? `The list "${list}" already exists!` : '');
    })();
  }, [list]);

  const [namesError, setNamesError] = useState('');
  useEffect(() => {
    if (!rawNames.length) {
      setNamesError('');
      return;
    }

    if (!names.length) {
      setNamesError('Please fill out this field.');
      return;
    }

    if (names.length <= shortlistLength) {
      setNamesError(
        `The list must have at least ${shortlistLength + 1} distinct names.`
      );
      return;
    }

    setNamesError('');
  }, [names, shortlistLength]);

  async function submit() {
    if (!formRef.current?.reportValidity()) {
      return;
    }

    await dao.createNewList(list, names, shortlistLength);

    route(`/play/${list}`);
  }

  return (
    <Stack
      ref={formRef}
      spacing={4}
      sx={{ flexGrow: 1, padding: 2 }}
      component="form"
      autoComplete="off"
    >
      <TextField
        label="List"
        value={rawList}
        autoFocus
        required
        maxLength={32}
        type="text"
        pattern="[\w -]+"
        title="Use only letters, numbers, and dashes"
        helperText={
          listError || 't-shirt colors, baby names, theme for the party…'
        }
        onChange={({ currentTarget }) => setRawList(currentTarget.value)}
        error={Boolean(listError)}
      />

      <TextField
        label="Names"
        value={rawNames}
        required
        multiline
        rows={10}
        helperText={
          namesError ||
          'Paste your long list into the text field, one item per line!'
        }
        onChange={({ currentTarget }) => setRawNames(currentTarget.value)}
        error={Boolean(namesError)}
      />

      <FormLabel>Size of shortlist</FormLabel>
      <Slider
        value={shortlistLength}
        min={2}
        max={7}
        valueLabelDisplay="on"
        marks
        onChange={(event, value) =>
          typeof value === 'number' && setShortlistLength(value)
        }
      />

      <Fab
        sx={{ position: 'fixed', bottom: 16, insetInlineEnd: 16 }}
        color="primary"
        aria-label="add"
        onClick={() => void submit()}
      >
        <Add />
      </Fab>
    </Stack>
  );
}
