import { ShortlistsDb, Item, List } from './db';
import { getValueOrThrow } from './typing';

export class ShortlistsDao {
  constructor(readonly db: ShortlistsDb) {}

  async getAllLists(): Promise<ReadonlyArray<string>> {
    const lists = await this.db.getAllKeys('lists');
    return lists.sort();
  }

  async getList(list: string): Promise<List | undefined> {
    return this.db.get('lists', list);
  }

  async createNewList(
    list: string,
    names: ReadonlyArray<string>,
    shortlistLength: number
  ): Promise<void> {
    const tx = this.db.transaction(['lists', 'items'], 'readwrite');

    await Promise.all([
      void tx.objectStore('lists').add({ list, shortlistLength }),
      names.map((name) =>
        tx.objectStore('items').add({
          list: list,
          name,
          rejected: false,
          survived: 0,
        })
      ),
    ]);

    tx.commit();
    return tx.done;
  }

  async getItemsFromList(list: string): Promise<ReadonlyArray<Item>> {
    return this.db.getAllFromIndex('items', 'by-list', list);
  }

  async rejectItem(
    list: string,
    loser: string,
    survivors: string[]
  ): Promise<void> {
    const tx = this.db.transaction('items', 'readwrite', {
      durability: 'strict',
    });

    async function updateLoser() {
      const loserRow = getValueOrThrow(await tx.store.get([list, loser]));
      await tx.store.put({ ...loserRow, rejected: true });
    }

    async function updateSurvivor(survivor: string) {
      const survivorRow = getValueOrThrow(await tx.store.get([list, survivor]));
      await tx.store.put({
        ...survivorRow,
        survived: survivorRow.survived + 1,
      });
    }

    await Promise.all([updateLoser(), survivors.map(updateSurvivor)]);

    tx.commit();
    return tx.done;
  }

  async undoRejectItem(
    list: string,
    loser: string,
    survivors: string[]
  ): Promise<void> {
    const tx = this.db.transaction('items', 'readwrite');

    async function updateLoser() {
      const loserRow = getValueOrThrow(await tx.store.get([list, loser]));
      await tx.store.put({ ...loserRow, rejected: false });
    }

    async function updateSurvivor(survivor: string) {
      const survivorRow = getValueOrThrow(await tx.store.get([list, survivor]));
      await tx.store.put({
        ...survivorRow,
        survived: survivorRow.survived - 1,
      });
    }

    await Promise.all([updateLoser(), survivors.map(updateSurvivor)]);

    tx.commit();
    return tx.done;
  }

  async resetList(list: string): Promise<ReadonlyArray<Item>> {
    const tx = this.db.transaction('items', 'readwrite');
    const itemRows = await tx.store.index('by-list').getAll(list);
    const resetItemRows = itemRows.map((itemRow) => ({
      ...itemRow,
      rejected: false,
      survived: 0,
    }));

    await Promise.all([resetItemRows.map((itemRow) => tx.store.put(itemRow))]);

    tx.commit();
    await tx.done;

    return resetItemRows;
  }

  async deleteList(list: string): Promise<void> {
    const tx = this.db.transaction(['lists', 'items'], 'readwrite');

    async function deleteItems() {
      const itemKeys = await tx
        .objectStore('items')
        .index('by-list')
        .getAllKeys(list);
      await Promise.all(
        itemKeys.map((itemKey) => tx.objectStore('items').delete(itemKey))
      );
    }

    await Promise.all([tx.objectStore('lists').delete(list), deleteItems()]);

    tx.commit();
    return tx.done;
  }
}
